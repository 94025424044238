<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：客户期初管理主页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <!-- 客户期初管理 -->
  <div class="AppTreeTableContent" id="crm_default_body">
    <!-- 资料左边列表 -->
    <div class="AppLeftBox">
      <!-- 客户期初分类 -->
      <div class="commodityLeft_product">
        <!-- 客户期初分类列表 -->
        <div class="commodityLeft_list">
          <div class="commodity_btn" @click="getClassifyData(0)">
            <i class="el-icon-menu"></i>
            <span>全部分类</span>
          </div>
          <div>
            <el-tree :data="crmClassfiy" :props="defaultProps" node-key="id" ref="tree" default-expand-all
              :expand-on-click-node="false" @current-change="getClassifyData">
              <span class="custom-tree-node" slot-scope="{ node, crmClassfiy}">
                <span>{{ node.label }}</span>
                <span>
                  <el-button type="text" size="mini">
                    <i class="el-icon-more"></i>
                  </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>

    <!-- 资料分类拉伸 -->
    <div class="AppHandelBox"></div>

    <!-- 资料操作内容 -->
    <div class="AppRightBox">
      <commodity-view ref="commodityIndex" :selClassifyId="selClassifyId" :is_execute="is_execute"></commodity-view>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入请求
  import api from '@/network/system/dictionary';//数据字典接口
  import req from '@/network/datum/supplier';//客户期初接口

  //导入组件
  import CommodityView from './crm_view.vue';//客户期初列表组件

  export default {
    name: 'commodityCrmIndex',
    data() {
      return {
        //页面数据
        crmClassfiy: [],//客户期初分类数据
        selClassifyId:0,//当前选择的客户分类数据

        //改变tree属性名
        defaultProps: {
          children: 'children',
          label: 'name'
        },

        // 辅助数据
        is_execute:false,//是否执行查询(用于每次点击时都执行一次查询)

        // loading框
        loading: '', //loading框加载
      }
    },
    computed: {
      ...mapState({
        CustomerClassfiy: state => state.system.CustomerClassfiy, //客户分类数据
      }),
    },
    created() {
      //获取客户期初分类列表
      this.getcrmClassfiy();
    },
    mounted() {
      // 初始化分类栏目拉伸
      this.commonJsExtend.getDunamicWidth();
    },
    methods: {
      ...mapActions([
        'getCustomerClassify', //请求客户分类数据
      ]),

      /* 查询客户期初分类列表 */
      async getcrmClassfiy() {
        if(this.CustomerClassfiy.length == 0){
          await this.getCustomerClassify();
        }
        //循环前清空
        this.crmClassfiy = [];
        //循环获取已启用分类
        this.CustomerClassfiy.forEach((item,index)=>{
          if(item.is_erp == 1){
            this.crmClassfiy.push(item);
          }
        })
      },

      /* 获取当前分类数据 */
      getClassifyData(data){
        //只要发生该时间就进行一次查询
        this.is_execute  = !this.is_execute;
        //判断查询类型
        if(data == 0){
          this.selClassifyId = 0;
        }else{
          this.selClassifyId = data.id;
        }
      },
    },
    components: {
      CommodityView,
    }
  }
</script>

<style lang="less">
  .AppTreeTableContent {
    height: 100%;
    width: 100%;

    // border: 1px solid black;
    // 左边分类框
    .AppLeftBox {

      // padding: 10px 0;
      // 商品分类
      .commodityLeft_product {
        width: calc(100% - 10px);
        padding: 10px 0 10px 10px;

        // border: 1px solid black;
        .commodityLeft_head {
          //头部提示信息
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          background-color: @form_bgColer;

          // border: 1px solid black;
          .title {
            //标题
            font-weight: bold;
            // border: 1px solid black;
          }

          .icon {
            //图标
            cursor: pointer;
            transform: rotate(90deg);
          }

          .icon:hover {
            color: @theme;
          }
        }

        .commodityLeft_list {
          //商品列表
          height: calc(100% - 10px);
          overflow: auto;
          padding: 10px;

          // border: 1px solid black;
          .commodity_btn {
            line-height: 28px;
            height: 28px;
            margin-left: 0px;
            font-size: 14px;
            cursor: pointer;
            color: #606266;
          }

          .commodity_btn:hover {
            background-color: #F2F6FC;
          }

          .custom-tree-node {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            // border: 1px solid red;
            button {
              color: white;
            }
          }

          .custom-tree-node:hover {
            button {
              color: @theme;
            }
          }
        }

        .commodityLeft_classfi {
          height: calc(100% - 72px);
          // background: @assist;
          padding: 10px;
          display: flex;

          // border: 1px solid red;
          .classfi_ul {
            width: 100%;
            cursor: pointer;

            // border: 1px solid black;
            li {
              padding: 10px 0;
              text-align: center;
              // border: 1px solid black;
            }

            li:hover {
              color: @theme;
            }
          }
        }
      }

      // 管理分类
    }

    .commodityHandelBox {}
  }
</style>

<!-- 导入单据公共样式 -->
<style lang="less" src="@/less/Comm/comm_billing.less"></style>
