<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：客户期初管理视图页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview">
    <!-- 客户期初操作框 -->
    <div class="billingview_headoperate">
      <!-- 标题 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="getCrmListData(0)">
            全部
          </li>
          <li @click="getCrmListData(conditionType)">
            刷新<i class="el-icon-refresh-right"></i>
          </li>
          <li>
            <el-popover placement="bottom_right" width="400" v-model="show_searchBox" trigger="manual">
              <div class="billingview_searchTitle">
                <span>根据以下条件搜索:</span>
              </div>
              <el-form ref="form" :model="searchData" size="mini" label-width="100px">
                <el-form-item label="客户名称:">
                  <el-input v-model="searchData.customerName" size="small" placeholder="请输入搜索内容"></el-input>
                </el-form-item>
                <el-form-item label="联系人姓名:">
                  <el-input v-model="searchData.linkManName" size="small" placeholder="请输入搜索内容"></el-input>
                </el-form-item>
                <el-form-item label="联系人邮箱:">
                  <el-input v-model="searchData.linkManEmail" size="small" placeholder="请输入搜索内容"></el-input>
                </el-form-item>
                <el-form-item label="联系人手机号:">
                  <el-input v-model="searchData.linkManMobile" size="small" placeholder="请输入搜索内容"></el-input>
                </el-form-item>
                <el-form-item label="入库时间:">
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="searchData.startTime" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                  <el-col class="line" :span="3" :push="1"> - </el-col>
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="searchData.endTime" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <div class="billingview_searchBtnBox">
                    <el-button type="primary" @click="getCrmListData(2)">搜索</el-button>
                    <el-button @click="closeSearch">取消</el-button>
                  </div>
                </el-form-item>
              </el-form>
              <span slot="reference" class="billingview_searchBut" @click="show_searchBox=!show_searchBox">
                查询<i class="el-icon-search"></i>
              </span>
            </el-popover>
          </li>
        </ul>
      </div>

      <!-- 操作列表 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="generateCustoimerCode">
            <i></i>生成编码
          </li>
        </ul>
      </div>
    </div>

    <!-- 客户期初表格 -->
    <div class="billingview_tablewrapper billing_table">
      <el-table id="supplier_table" :data="crmData" height="100%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50">
          <template slot="header" slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <li v-for="elem in showListFiledData" v-if="elem.classify == -1">
                    <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                  </li>
                </ul>
              </div>
              <i class="el-icon-setting"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column label="操作" fixed width="50">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" title="设置期初" @click="setCustomerDefaultCost(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column prop="organization_code" label="客户编号" sortable width="150" v-if="showListFiledArr.includes(0)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="customer_name" label="客户名称" width="220" v-if="showListFiledArr.includes(1)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="customerDefaultCosts.total_payment" label="待收款总额" width="120"
          v-if="showListFiledArr.includes(2)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="email" label="邮箱" width="220" v-if="showListFiledArr.includes(3)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="country" label="所在国家" width="150" v-if="showListFiledArr.includes(4)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="province" label="所在省份" width="80" v-if="showListFiledArr.includes(5)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="city" label="所在城市" width="80" v-if="showListFiledArr.includes(6)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="address" label="详细地址" width="220" v-if="showListFiledArr.includes(7)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="website" label="公司网址" width="200" v-if="showListFiledArr.includes(8)"
          show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="linkmanName" label="联系人姓名" width="80" v-if="showListFiledArr.includes(9)"
          show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="customerDefaultCosts.tax_rage" label="税率(%)" width="80"
          v-if="showListFiledArr.includes(10)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="customerDefaultCosts.exchange_rage" label="汇率(%)" width="80"
          v-if="showListFiledArr.includes(11)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="enrol_time" label="创建时间" width="180" v-if="showListFiledArr.includes(11)"
          show-overflow-tooltip></el-table-column>
      </el-table>
    </div>

    <!-- 表格分页 -->
    <div class="billing_tablePage">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size.sync="currentPageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="totalSize">
      </el-pagination>
    </div>

    <!-- 设置期初弹框 -->
    <el-dialog title="设置期初" :visible.sync="settVisible" width="50%" append-to-body top="10vh" :before-close="close_box"
      :close-on-click-modal="false" id="customerEditBox">
      <div class="billing_dialog_form scollStyle form">
        <el-form :model="customerData" :rules="from_rules" ref="add_ruleform" label-width="120px" size="small">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="12">
              <el-form-item label="客户编号:">
                <el-input v-model="customerData.code" disabled placeholder="请输入客户编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="客户名称:">
                <el-input v-model="customerData.name" disabled show-overflow-tooltip placeholder="请输入客户名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="密码设置:">
                <el-input v-model="customerData.password_login" type="password" placeholder="请输入登录密码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="密码有效期:">
                <el-date-picker v-model="customerData.password_vaild" @input="getSelectData($event,'password_vaild')"
                  type="date" placeholder="请选择密码有效期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="开启自主下单:">
                <el-checkbox v-model="customerData.is_self_order"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="自主修改密码:">
                <el-checkbox v-model="customerData.password_is_edit"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="客户类别:">
                <el-input v-model="customerData.classfiy" disabled show-overflow-tooltip placeholder="请输入客户类别">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系人:">
                <el-input v-model="customerData.linkman" disabled placeholder="请输入联系人"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系人电话:">
                <el-input v-model="customerData.tel" disabled placeholder="请输入联系人电话"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Email:">
                <el-input v-model="customerData.email" disabled placeholder="请输入邮箱地址"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="客户地址:">
                <el-input v-model="customerData.address" disabled show-overflow-tooltip placeholder="请输入客户地址">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="座机:">
                <el-input v-model="customerData.phone" disabled placeholder="请输入座机号码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结算币种:" prop="currencyId">
                <el-select class="iteminput" v-model="customerData.currencyId" size="small" placeholder="请选择">
                  <el-option v-for="item in FinanceCurrency" :key="item.id" :label="item.name" :value="item.id"
                    v-if="item.is_enable==1" @click.native="getCurrencyData(item)"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="信任额:" prop="trust">
                <el-input v-model="customerData.trust" oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                  placeholder="信任额(对应所选币种)"></el-input>
              </el-form-item>
            </el-col>
         <!--   <el-col :span="12">
              <el-form-item label="信誉周期(天):" prop="time">
                <el-input v-model="customerData.time" oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                  placeholder="请输入信誉周期"></el-input>
                  <el-checkbox v-model="whetherMaterialProcessing">物料加工</el-checkbox>
                  <el-checkbox v-model="whetherMaterialProcessing">物料加工</el-checkbox>
                  <el-checkbox v-model="whetherMaterialProcessing">物料加工</el-checkbox>
              </el-form-item>
            </el-col> -->
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="信用时间:" prop="time">
                <el-input v-model="customerData.time" oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                  placeholder="请输入信誉周期"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
             <el-checkbox class="anniu" v-model="saleButton" @change="saleButtonChage()">下单后</el-checkbox>
             <el-checkbox class="anniu" v-model="depotButton" @change="depotButtonChage()">出货后</el-checkbox>
             <el-checkbox class="anniu" v-model="saleMesButton" @change="saleMesButtonChage()">对账后</el-checkbox>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="期初应收:" prop="wait">
                <el-input v-model="customerData.wait" oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                  placeholder="请输入期初应收"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="待收款:" prop="">
                <el-input disabled='' v-model="customerData.total_payment" placeholder="待收款金额"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="纳税人识别号:" prop="receipt_code">
                <el-input v-model="customerData.receipt_code" placeholder="请输入纳税人识别号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户人名称:" prop="account_name">
                <el-input v-model="customerData.account_name" placeholder="请输入开户人名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="开户银行:" prop="account_bank">
                <el-input v-model="customerData.account_bank" placeholder="请输入开户银行"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户账号:" prop="account_number">
                <el-input v-model="customerData.account_number" oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                  placeholder="请输入开户账号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="默认税率:" prop="taxRage">
                <el-input v-model="customerData.taxRage" placeholder="请输入默认税率" style="width: 90%;"
                  oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input><span style="padding-left: 5px;">%</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="默认汇率:" prop="exchangeRage">
                <el-input v-model="customerData.exchangeRage" placeholder="请输入默认汇率" style="width: 90%;"
                  oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')">
                </el-input><span style="padding-left: 5px;">%</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="价格是否含税:" prop="isRate">
                <el-select class="iteminput" v-model="customerData.isRate" size="small" placeholder="请选择">
                  <el-option v-for="item in isRate" :key="item.id" :label="item.name" :value="item.id"
                    @click.native="getIsRateData(item)"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="对账单结存时间:" prop="balanceTime">
                每月
                <el-input-number v-model="customerData.balanceTime" controls-position="right" :min="0" :max="31">
                </el-input-number>
                号
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="close_box">取 消</el-button>
        <el-button size="small" type="primary" @click="saveData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入请求接口
  import api from '@/network/system/dictionary'; //数据字典相关接口
  import req from '@/network/crm/crm.js'; //客户相关接口

  export default {
    name: 'crmView',
    props: {
      //客户分类数据
      selClassifyId: {
        type: Number,
        default: 0
      },
      //是否执行查询
      is_execute: {
        type: Boolean,
        default: false
      },
    },
    data() {
      // var validPasswordLogin = (rule, value, callback) => {
      //   console.log(value);
      //   if (value == 1) {
      //     callback(new Error("不能输入1"))
      //   } else {
      //     callback();
      //   }
      // }
      return {
        //条件查询相关
        searchData: {
          customerName: '', //客户名称
          linkManName: '', //联系人名称
          linkManEmail: '', //联系人邮箱
          linkManMobile: '', //联系人手机
          startTime: '', //开始时间
          endTime: '', //结束时间
        }, //查询条件
        conditionType: 0, //当前查询的条件(0:全部 其他:分类)

        //界面数据
        operatedState: 0, //判断是修改还是新增
        crmData: [], //客户数据
        customerData: {
          //客户信息
          id: '', //客户id
          code: '', //客户代码
          name: '', //客户名称
          email: '', //客户邮箱email
          classfiy: '', //客户类别(名称)
          linkman: '', //联系人
          tel: '', //联系人电话
          address: '', //客户地址
          phone: '', //座机
          // 期初信息
          currencyId: '', //币种id
          wait: '', //期初应收
          total_payment: '', //应收款
          trust: '', //信任额度
          time: '', //信任时间
          taxRage: '', //默认税率
          isRate:-1,//价格是否含税(-1:未选择 0:否  1:是)
          exchangeRage: '', //默认汇率
          balanceTime: '', //每月对账时间
          receipt_code: '', //纳税人识别号
          account_name: '', //开户人名称
          account_bank: '', //开户银行
          account_number: '', //开户账号
          is_self_order: '', //是否自主下单
          password_login: '', //登录密码
          password_is_edit: '', //密码是否可编辑(0:否  1:是)
          password_vaild: '', //密码有效期
        },

        // 辅助数据
        from_rules: {}, //表单验证规则
        showListFiledData: [], //列表可显示的字段
        showListFiledArr: [], //列表需要显示的

        //控制开关
        show_searchBox: false, //是否显示搜索
        isEnable: false, //是否启用分页
        settVisible: false, //控制新增修改弹框

        //客户配置
        saleButton:false,
        depotButton:false,
        saleMesButton:false,

        // 分页相关
        currentPage: 1, //页码
        currentPageSize: 20, //页面显示数据条数
        totalSize: 0, //总页数

        //loading框
        loading: '', //加载loading
      }
    },
    computed: {
      ...mapState({
        FinanceCurrency: state => state.system.FinanceCurrency, //交易币种
        OrderSettlement: state => state.system.OrderSettlement, //结算方式
        userPowerInfo: state => state.user.userPowerInfo, //用户权限
        CustomerClassfiy: state => state.system.CustomerClassfiy, //客户分类数据
        isRate: state => state.system.isRate, //是否含税
        customerListFileData: state => state.bill_list_config.customerListFileData, //客户列表可显示字段
      })
    },
    watch: {
      /* 监听点击分类 */
      is_execute(newVal) {
        //判断是查询的全部还是分类
        if (this.selClassifyId == 0) {
          this.conditionType = 0;
        } else {
          this.conditionType = 1;
        }
        //查询货品列表数据
        this.getCrmListData(this.conditionType);
      },
      /* 监听控制新增弹框数据发生改变 */
      show_addBox(newVal) {
        if (!newVal) {
          //关闭时默认请求一次数据
          this.getCrmListData(this.conditionType);
        } else {
          //打开时默认初始化一次vuex数据
          this.INIT_PRODUCT_DATA();
        }
      },
      // 监听显示列表数据发生变化
      showListFiledData: {
        handler(newVal) {
          this.getCurShowFiledArr();
        },
        deep: true
      },
    },
    created() {
      //初始化表单验证
      this.initRules();
    },
    mounted() {
      //初始化vuex相关数据
      this.initVuexData();
      //初始化列表显示字段数据
      this.initListShowFiledData();
    },
    methods: {
      ...mapMutations([]),
      ...mapActions([
        "getFinanceCurrency", //获取交易币种信息
        'getUserPowerInfo', //获取用户操作权限
        'getCustomerClassify', //请求客户分类数据
      ]),

      /* 初始化界面所需vuex相关数据 */
      async initVuexData() {
        //客户分类
        if (!this.CustomerClassfiy.length > 0) {
          await this.getCustomerClassify();
        }
        //交易币种
        if (!this.FinanceCurrency.length > 0) {
          await this.getFinanceCurrency();
        }
        //默认获取全部客户数据
        this.getCrmListData(0);
      },

      saleButtonChage(){
         if(this.saleButton){
           this.depotButton=false
           this.saleMesButton=false
         }
      },
      depotButtonChage(){
        if(this.depotButton){
          this.saleButton=false
          this.saleMesButton=false
        }
      },
      saleMesButtonChage(){
        if(this.saleMesButton){
          this.depotButton=false
          this.saleButton=false
        }
      },

      /* 获取客户列表数据type(0:全部  1:分类  2:高级查询) */
      async getCrmListData(type) {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(21, 1, 1, '查询')) {
            this.crmData = []
            return
          }
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(21, 1, 1, '查询')) {
            this.crmData = []
            return
          }
        }

        //设置查询类型
        this.conditionType = type;

        //公共查询条件
        let data = {
          pageIndex: this.currentPage, //当前页
          pageSize: this.currentPageSize, //当前页码
        }

        //判断是查询全部还是根据分类查询
        if (type == 0) {//全部查询
          let classifyIdArr = [];
          this.CustomerClassfiy.forEach((item, index) => {
            classifyIdArr.push(item.id);
          })
          data.classify_id = classifyIdArr.join(",");
        } else if(type == 1){//分类查询
          data.classify_id = this.selClassifyId;
        } else if(type == 2){//高级查询
          //定义高价查询条件
          let {
            customerName, //客户名称
            linkManName, //联系人名称
            linkManEmail, //联系人邮箱
            linkManMobile, //联系人手机
            startTime, //开始时间
            endTime //结束时间
          } = this.searchData
          let today = new Date()
          today.setHours(0, 0, 0, 0)
          if (startTime != '' || endTime != '') { //起止时间其中一个不为空
            if (startTime == '' || endTime == '') { //起止时间其中一个为空
              this.$message({
                type: 'warning',
                message: '查询条件为起止时间时必须两个都填写!',
                duration: this.elDuration
              })
              return
            } else if (endTime.getTime() - startTime.getTime() < 0) { //结束日期不能早于开始日期
              this.$message({
                type: 'warning',
                message: '结束日期不能早于开始日期!',
                duration: this.elDuration
              })
              return
            } else if (today.getTime() < startTime.getTime() || today.getTime() < endTime
              .getTime()) { //所选择的日期不能早于当前日期
              this.$message({
                type: 'warning',
                message: '所选择的日期不能早于当前日期!',
                duration: this.elDuration
              })
              return
            } else { //限制日期选择区间
              let duringTime = (endTime.getTime() - startTime.getTime()) / (24 * 60 * 60 * 1000)
              if (duringTime > 90) {
                this.$message({
                  type: 'warning',
                  message: '日期区间不能超过90天!',
                  duration: this.elDuration
                })
                return
              }
            }
          }

          //将分页设置为不可用
          this.isEnable = true;

          // 高级查询条件
          //客户名称
          if (customerName != '') {
            data.customerName = customerName;
          }
          //联系人名称
          if (linkManName != '') {
            data.linkManName = linkManName;
          }
          //联系人邮箱
          if (linkManEmail != '') {
            data.linkManEmail = linkManEmail;
          }
          //联系人手机
          if (linkManMobile != '') {
            data.linkManMobile = linkManMobile;
          }
          //开始时间
          if (startTime != '') {
            data.startTime = startTime;
          }
          // 结束时间
          if (endTime != '') {
            data.endTime = endTime;
          }
          //关闭高级查询弹框
          this.show_searchBox = false;
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#crm_default_body", 4, '客户列表信息获取中,请稍候...');
        //发送请求
        req.findCustomerByCondition(data).then(res => {
          //关闭loading框
          this.loading.close();
          //判断接口返回数据
          if (res.code == 200) {
            //获取当前列表数据
            this.crmData = res.data.rows;
            //获取总条数
            this.totalSize = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 设置客户期初 */
      setCustomerDefaultCost(data) {
        
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(21, 1, 4, '修改')) {
          return
        }
        //获取主联系人信息
        let linkman = '';
        if (data.linkman.length > 0) {
          data.linkman.forEach((item, index) => {
            if (item.isMainLinkman == 1) {
              linkman = item;
            }
          })
        }
        //获取客户分类
        let classifyName = '';
        this.CustomerClassfiy.forEach((item, index) => {
          if (item.id == data.classify_id) {
            classifyName = item.name;
          }
        })
        //获取客户信息
        this.customerData = {
          //客户信息
          id: data.customer_id, //客户id
          code: data.organization_code, //客户代码
          name: data.customer_name, //客户名称
          email: data.email, //客户邮箱email
          classfiy: classifyName, //客户类别(名称)
          linkman: linkman == '' ? '' : linkman.fullName, //联系人
          tel: linkman == '' ? '' : linkman.phone, //联系人电话
          address: data.address, //客户地址
          phone: data.phone, //座机
          // 期初信息
          currencyId: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.currency_type, //币种id
          wait: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.wait_gathering, //期初应收
          total_payment: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.total_payment, //应收款
          taxRage: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.tax_rage, //默认税率
          isRate: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.is_rate, //价格是否含税
          exchangeRage: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.exchange_rage, //默认汇率
          balanceTime: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.balance_time, //每月对账时间
          receipt_code: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.receipt_code, //纳税人识别号
          account_name: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.account_name, //开户人名称
          account_bank: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.account_bank, //开户银行
          account_number: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.account_number, //开户账号
          is_self_order: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.is_self_order, //是否自主下单
          password_login: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.password_login, //登录密码
          password_is_edit: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts
            .password_is_edit, //密码是否可编辑(0:否  1:是)
          password_vaild: data.customerDefaultCosts == null ? '' : data.customerDefaultCosts.password_vaild, //密码有效期
          //信誉信息
          trust: data.customerTrustCosts == null ? '' : data.customerTrustCosts.reputation_limit, //信任额度
          time: data.customerTrustCosts == null ? '' : data.customerTrustCosts.reputation_time, //信任时间
        }
       if(!!data.customerTrustCosts){
         if(!!data.customerTrustCosts.credit_allocation){
           if(data.customerTrustCosts.credit_allocation==1){
             this.saleButton=true
           }
           if(data.customerTrustCosts.credit_allocation==2){
             this.depotButton=true
           }
           if(data.customerTrustCosts.credit_allocation==3){
             this.saleMesButton=true
           }
         }
       }

        //****************处理复选框数据****************
        //自主下单
        if (this.customerData.is_self_order == 1) {
          this.customerData.is_self_order = true;
        } else {
          this.customerData.is_self_order = false;
        }
        //密码是否可编辑
        if (this.customerData.password_is_edit == 1) {
          this.customerData.password_is_edit = true;
        } else {
          this.customerData.password_is_edit = false;
        }

        // 判断是新增还是修改期初信息
        this.operatedState = data.customerDefaultCosts == null ? 0 : 1;

        //显示弹框
        this.settVisible = true;
      },

      /* 保存设置的期初信息 */
      saveData() {
        this.$refs['add_ruleform'].validate(valid => {
          if (valid) {
            var data = {
              customer_id: this.customerData.id,
              //期初信息
              tax_rage: this.customerData.taxRage,
              is_rate: this.customerData.isRate,
              exchange_rage: this.customerData.exchangeRage,
              balance_time: this.customerData.balanceTime,
              wait_gathering: this.customerData.wait,
              currency_type: this.customerData.currencyId,
              total_payment: this.customerData.total_payment,
              receipt_code: this.customerData.receipt_code, //纳税人识别号
              account_name: this.customerData.account_name, //开户人名称
              account_bank: this.customerData.account_bank, //开户银行
              account_number: this.customerData.account_number, //开户账号
              is_self_order: this.customerData.is_self_order == true ? 1 : 0, //是否自主下单
              password_login: this.customerData.password_login, //登录密码
              password_is_edit: this.customerData.password_is_edit == true ? 1 : 0, //密码是否可编辑(0:否  1:是)
              password_vaild: this.customerData.password_vaild, //密码有效期
              //信誉信息
              reputation_limit: this.customerData.trust,
              reputation_time: this.customerData.time,
            }
            //信誉配置
            if(this.saleButton){
              data.credit_allocation=1
            }
            else if(this.depotButton){
              data.credit_allocation=2
            }
            else if(this.saleMesButton){
              data.credit_allocation=3
            }
            //加载loading框
            this.loading = this.commonJsExtend.customLoading("#customerEditBox", 4, '客户信息更新中,请稍候...');
            //发送请求
            req.setCustomerOpenning(data).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '设置期初成功',
                  duration: this.elDuration
                })
                //关闭loading框
                this.loading.close();
                //关闭弹框
                this.close_box();
                //获取最新数据
                this.getCrmListData(this.conditionType)
              } else {
                //关闭loading框
                this.loading.close();
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        })
      },

      /* 获取日期选择弹框数据*/
      getSelectData(date, filed) {
        //将选择后的日期格式进行转换,不转换存的是国际标准时间
        this.customerData[filed] = this.commonJsExtend.getDateTime(date, 0);
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 分页大小发生改变 */
      handleSizeChange(val) {
        //获取当前页面大小
        this.currentPageSize = val;
        //获取列表数据
        this.getCrmListData(this.conditionType);
      },

      /* 当前页面发生改变 */
      handleCurrentChange(val) {
        //获取当前页下标
        this.currentPage = val;
        //获取列表数据
        this.getCrmListData(this.conditionType);
      },

      /* 获取选择币种信息 */
      getCurrencyData(data) {
        this.customerData.currencyId = data.id;
      },

      /* 获取价格是否含税 */
      getIsRateData(data){
        this.customerData.isRate = data.id;
      },

      /* 单据页面大小信息改变 */
      billsPageChange(val) {
        //获取单据当前页信息
        this.billsPageData.currentPageSize = val;
        //获取数据
        this.getBillsData(this.currentBillsProduct, this.currentBillsType);
      },

      /* 单据列表当前页信息改变 */
      getCurrentPage(val) {
        //获取单据当前页信息
        this.billsPageData.currentPage = val;
        //获取数据
        this.getBillsData(this.currentBillsProduct, this.currentBillsType);
      },

      /*初始化列表显示字段数据 */
      initListShowFiledData() {
        //获取界面显示数组
        this.showListFiledArr = this.commonJsExtend.initListShowFiledData('crmListArr', this.customerListFileData)
          .showListFiledArr;
        //获取控制数组
        this.showListFiledData = this.commonJsExtend.initListShowFiledData('crmListArr', this.customerListFileData)
          .showListFiledData;
      },

      /* 获取当前需要显示的列表字段 */
      getCurShowFiledArr() {
        //将数据存入cookie
        this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('crmListArr', this.showListFiledData);
      },

      /* 关闭搜索 */
      closeSearch() {
        this.show_searchBox = false
        Object.assign(this.$data.searchData, this.$options.data().searchData)
      },

      /* 关闭新增,修改弹框 */
      close_box() {
        this.settVisible = false;
      },

      /* 一键生成客户编码 */
      generateCustoimerCode() {
        let data = {
          type: 0, //需要生成编码的客户(0:全部  1:指定客户分类  2:指定客户)
          classifyId: 2333, //客户分类id，当type为1时填写
          customerId: '' //客户id，当type为2时填写
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#customer_table", 4, "客户编码生成中,请稍候!"); //加载loading框
        crmApi.generateCustoimerCode(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.getCrmListData(this.conditionType);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 初始化表单验证规则 */
      initRules() {
        //表单验证规则
        // this.from_rules = {
        //   password_login: [{
        //     validator: validPasswordLogin,
        //     trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
        //   }]
        // }
      },
    },
    components: {}
  }
</script>
<style lang="less" scoped>
  /* 新增修改弹框 */
  .billing_dialog_form {
    height: 60vh;
    overflow: auto;
  }

  .prodoctName {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;
  }

  .rotateX {
    transform: rotateX(180deg);
  }
  .anniu{
    padding-left: 10px;
  }

  .billingDetailBox {
    height: 45vh;
  }

  // 单据分页弹框
  .billsPageBox {
    padding: 5px 0;
    // border: 1px solid black;
  }
</style>
